@mixin clearfix {
    zoom: 1;

    &:before,
    &:after {
        content: "\0020";
        display: block;
        height: 0;
        overflow: hidden;
    }

    &:after {
        clear: both;
    }
}

@mixin background-gradient($startColor: #3C3C3C, $endColor: #999999) {
    background-color: $startColor;
    background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient(top, $startColor, $endColor);
    background-image: -moz-linear-gradient(top, $startColor, $endColor);
    background-image: -ms-linear-gradient(top, $startColor, $endColor);
    background-image: -o-linear-gradient(top, $startColor, $endColor);
    background-image: linear-gradient(top, $startColor, $endColor);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorStr='#{$startColor}', endColorStr='#{$endColor}');
}

@mixin background-size($width: 100%, $height: 100%) {
    -moz-background-size: $width $height;
    -webkit-background-size: $width $height;
    background-size: $width $height;
}

@mixin border-radius($radius: 5px) {
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-radius-separate($topLeftRadius: 5px, $topRightRadius: 5px, $bottomLeftRadius: 5px, $bottomRightRadius: 5px) {
    -webkit-border-top-left-radius: $topLeftRadius;
    -webkit-border-top-right-radius: $topRightRadius;
    -webkit-border-bottom-right-radius: $bottomRightRadius;
    -webkit-border-bottom-left-radius: $bottomLeftRadius;
    -moz-border-radius-topleft: $topLeftRadius;
    -moz-border-radius-topright: $topRightRadius;
    -moz-border-radius-bottomright: $bottomRightRadius;
    -moz-border-radius-bottomleft: $bottomLeftRadius;
    border-top-left-radius: $topLeftRadius;
    border-top-right-radius: $topRightRadius;
    border-bottom-right-radius: $bottomRightRadius;
    border-bottom-left-radius: $bottomLeftRadius;
}

@mixin box($orient: horizontal, $pack: center, $align: center) {
    display: -webkit-box;
    display: -moz-box;
    display: box;
    -webkit-box-orient: $orient;
    -moz-box-orient: $orient;
    box-orient: $orient;
    -webkit-box-pack: $pack;
    -moz-box-pack: $pack;
    box-pack: $pack;
    -webkit-box-align: $align;
    -moz-box-align: $align;
    box-align: $align;
}

@mixin box-rgba($r: 60, $g: 3, $b: 12, $opacity: 0.23, $color: #3C3C3C) {
    background-color: transparent;
    background-color: rgba($r, $g, $b, $opacity);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color}', endColorstr='#{$color}');
    zoom: 1;
}

@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4), $inset: "") {
    @if $inset !="" {
        -webkit-box-shadow: $inset $x $y $blur $color;
        -moz-box-shadow: $inset $x $y $blur $color;
        box-shadow: $inset $x $y $blur $color;
    }

    @else {
        -webkit-box-shadow: $x $y $blur $color;
        -moz-box-shadow: $x $y $blur $color;
        box-shadow: $x $y $blur $color;
    }
}

@mixin box-sizing($type: border-box) {
    -webkit-box-sizing: $type;
    -moz-box-sizing: $type;
    box-sizing: $type;
}

@mixin columns($count: 3, $gap: 10) {
    -webkit-column-count: $count;
    -moz-column-count: $count;
    column-count: $count;
    -webkit-column-gap: $gap;
    -moz-column-gap: $gap;
    column-gap: $gap;
}

@mixin double-borders($colorOne: #3C3C3C, $colorTwo: #999999, $radius: 0) {
    border: 1px solid $colorOne;
    -webkit-box-shadow: 0 0 0 1px $colorTwo;
    -moz-box-shadow: 0 0 0 1px $colorTwo;
    box-shadow: 0 0 0 1px $colorTwo;
    @include border-radius($radius);
}

@mixin flex($value: 1) {
    -webkit-box-flex: $value;
    -moz-box-flex: $value;
    box-flex: $value;
}

@mixin flip($scaleX: -1) {
    -moz-transform: scaleX($scaleX);
    -o-transform: scaleX($scaleX);
    -webkit-transform: scaleX($scaleX);
    transform: scaleX($scaleX);
    filter: FlipH;
    -ms-filter: "FlipH";
}

@mixin font-face($style-name, $file, $family) {
    $filepath: "../fonts/"+ $family + "/"+ $file;

    @font-face {
        font-family: $style-name;
        src: url('#{$filepath}.eot');
        src: url('#{$filepath}.eot?#iefix') format('embedded-opentype'),
        //    url('#{$filepath}.woff') format('woff'),
        url('#{$filepath}.ttf') format('truetype'),
        url('#{$filepath}.svg##{$style-name}') format('svg');
    }
}

@mixin outline-radius($radius: 5px) {
    -webkit-outline-radius: $radius;
    -moz-outline-radius: $radius;
    outline-radius: $radius;
}

@mixin resize($direction: both) {
    -webkit-resize: $direction;
    -moz-resize: $direction;
    resize: $direction;
}

@mixin rotate($deg: 0, $m11: 0, $m12: 0, $m21: 0, $m22: 0) {
    -moz-transform: rotate($deg + deg);
    -o-transform: rotate($deg + deg);
    -webkit-transform: rotate($deg + deg);
    -ms-transform: rotate($deg + deg);
    transform: rotate($deg + deg);
    filter: progid:DXImageTransform.Microsoft.Matrix(M11=#{$m11}, M12=#{$m12}, M21=#{$m21}, M22=#{$m22}, sizingMethod='auto expand');
    zoom: 1;
}

@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0, 0, 0, .4)) {
    text-shadow: $x $y $blur $color;
}

@mixin transform($params) {
    -webkit-transform: $params;
    -moz-transform: $params;
    transform: $params;
}

@mixin transition($declarations...) {

    @each $prefix in '-webkit-',
    '-moz-',
    '-ms-',
    '-o-',
    '' {
        #{$prefix}transition: $declarations;
    }
}

@mixin triple-borders($colorOne: #3C3C3C, $colorTwo: #999999, $colorThree: #000000, $radius: 0) {
    border: 1px solid $colorOne;
    @include border-radius($radius);
    -webkit-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
    -moz-box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
    box-shadow: 0 0 0 1px $colorTwo, 0 0 0 2px $colorThree;
}

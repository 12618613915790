@include font-face("CircularStd-Book", CircularStd-Book, "CircularStd");
@include font-face("Apercu Mono", apercu_mono, "ApercuMono");

html,
body {
    width: 100%;
    height: 100%;
}

body {
    background-color: #2e2c33;
    width: 100%;
    color: #f7d7d0;
    text-align: left;

    p,
    li,
    a {
        font-family: "CircularStd-Book";
        font-size: 0.91em;
        text-decoration: none;
        list-style: none;
    }

    a {
        color: #f7d7d069 !important;
        transition: color 0.2s linear;
        -webkit-transition: color 0.2s linear;
        -moz-transition: color 0.2s linear;
    }

    a:hover {
        color: #f7d7d0 !important;
    }

    ul {
        display: inline-block;

        li {
            list-style: none;
            display: block;
        }
    }

    //All titles
    .title {
        font-family: "CircularStd-Book";
        font-size: 2.12em;
        color: rgba(247, 215, 208, 0.507);
        padding-bottom: 0.4em;
    }

    nav {
        position: fixed;
        width: 100%;
        text-align: center;
        z-index: 10;

        ul {
            padding: 1em 0;
            font-size: 1em;

            li {
                float: left;
                color: #f7d7d069;

                a {
                    text-decoration: none;
                    color: #f7d7d069;
                }
            }

            .active a {
                color: #f7d7d0 !important;
                // text-decoration: underline;
            }
        }
    }

    .dropdown {
        background-color: #2e2c3388;
    }

    .grid-container {
        display: grid;
        justify-content: center;
        grid-template-columns: 0.1fr 0.45fr 0.45fr 0.1fr;
        grid-template-rows: auto;
        grid-template-areas:
            ". title title . "
            ". leftContent rightContent .";

        .grid-item {
            grid-area: title;
        }

        .leftSide {
            grid-area: leftContent;
        }

        .rightSide {
            grid-area: rightContent;
        }
    }

    //Main page Styling
    .wrapper {
        display: grid;
        position: relative;
        grid-template-columns: minmax(320px, 1fr);
        grid-template-rows: auto;
        grid-template-areas:
            "header"
            "works"
            "clients"
            "services"
            "contact"
            "footer";

        header {
            background-color: #2e2c33;
            grid-area: header;
            text-align: center;

            .chprLogo {
                // position: fixed;

                img {
                    width: 40%;
                    min-width: 220px;
                    max-width: 500px;
                }
            }

            .headerArrow {
                display: block;
                margin: 5em auto;
                width: 100px;
                height: 82px;
                background: url(../../../images/headerArrow.svg);
                background-size: 100px 82px;
                background-repeat: no-repeat;
            }

            .whoarewe {
                font-size: 1.2em;
                margin: 0 auto;
                max-width: 12em;
                padding-bottom: 4em;
            }
        }

        #works {
            grid-area: works;
            background-color: #f7d7d0;

            .slider {
                z-index: 1;

                //Slick Slider
                .slick-slide {
                    background-color: #f7d7d0;

                    .slick-item {
                        margin-right: 10%;
                    }
                }

                .slick-dots {
                    li a {
                        color: #fff;
                    }
                }

                li.slick-active a {
                    color: #2e2c33;
                    text-decoration: underline;
                }
            }
        }

        section {
            font-size: 0.85em;
            padding: 8.5em 0;

            .keepCentral {
                position: relative;
                width: 22em;
                margin: 0 auto;

                .arrowBox {
                    width: 100%;
                }
            }
        }

        //Clients
        #clients {
            grid-area: clients;
            background-color: #f7d7d0;
            color: #2e2c3388;
            padding-top: 5em;

            .title {
                color: #ffffff8a;
            }

            .clientsArrow {
                position: absolute;
                background-image: url(../../../images/clientsArrow.svg);
                background-repeat: no-repeat;
                width: 80px;
                height: 180px;
                left: -5px;
                top: -150px;
            }

            a {
                color: #2e2c3388 !important;
            }

            a:hover {
                color: #2e2c33 !important;
            }

            z-index: 0;
        }
    }

    //Services
    #services {
        grid-area: services;
        position: relative;
        background-color: #3f3755;
        z-index: -1;

        .servicesArrow {
            position: absolute;
            background-image: url(../../../images/servicesArrow.svg);
            background-repeat: no-repeat;
            width: 110px;
            height: 248px;
            top: -215px;
            left: 15px;
        }

        .grid-container {
            grid-template-columns: 0.4fr 0.5fr;
            grid-template-areas:
                ". title"
                ". rightContent";

            .grid-item {
                grid-area: title;
            }

            .rightSide {
                grid-area: rightContent;
            }
        }
    }

    //Contact
    #contact {
        grid-area: contact;
        justify-content: center;
        background-color: #2e2c33;
        z-index: 0;

        .divider {
            border: 0;
            height: 1px;
            background: rgba(247, 215, 208, 0.507);
        }

        .contactArrow {
            position: absolute;
            background-image: url(../../../images/contactArrow.svg);
            background-repeat: no-repeat;
            width: 180px;
            height: 170px;
            left: 1em;
            top: -190px;
        }

        .grid-container {
            display: grid;
            justify-content: center;
            grid-template-columns: repeat(2, minmax(2em, 9em)) 2em;
            grid-template-rows: auto;
            grid-template-areas:
                "title title ."
                "leftContent leftContent btt"
                "rightContent rightContent .";

            .grid-item {
                grid-area: title;
            }

            .rightSide {
                grid-area: rightContent;

                .divider2 {
                    display: none;
                }
            }

            .leftSide {
                grid-area: leftContent;
            }

            .backtotop {
                grid-area: btt;
                width: 6em;
                height: 1em;
                transform: rotate(-90deg);

                a {
                    position: absolute;
                    font-size: 0.8em;
                    // top: -15px;
                }
            }
        }
    }

    //Footer
    footer {
        grid-area: footer;
        // padding: 1em;
        background-color: #17161a;
        text-align: center;

        li,
        a {
            font-family: "Apercu Mono";
            color: #7c6c68;
            font-size: 0.8em;
            padding: 0.5em 0;
        }

        .priv-pol-btn {
            cursor: pointer;
        }
    }

    .close {
        display: none;
    }

    #privacyPolicy {
        position: fixed;
        background-color: #838790;

        top: 50%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 2em 0;
        z-index: 999999;

        .closeContainer {
            position: absolute;
            right: 30px;
            top: 10px;

            .close-button {
                a {
                    font-size: 0.9em;
                    color: #ede;
                }

                a:hover {
                    color: #000;
                }
            }
        }

        p,
        a {
            font-size: 1em;
            color: #fff;
        }

        p {
            max-width: 90%;
            margin: 0 auto;
        }

        a:hover,
        p:hover {
            color: #000;
            cursor: pointer;
        }

        .title h2 {
            // font-family: "Apercu Mono";
            font-size: 0.7em;

            color: #ddd;
            text-align: center;
        }

        .content {
            max-width: 30em;
            margin: 0 auto;

            p {
                font-family: "Apercu Mono";
                font-size: 0.6em;
                color: #fff;
                // letter-spacing: 0.5px;
                line-height: 1.4em;
            }
        }
    }
}

//Big Phones
@media only screen and (min-width: 375px) {
    body {
        .wrapper {
            header {
                .chprLogo img {
                    padding-top: 25em;
                }
            }
        }

        section {
            font-size: 0.9em;
            padding: 10em 0;

            .keepCentral {
                width: 1em;
            }
        }
    }
}

//Landscape
@media only screen and (min-width: 480px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
    body {
        .wrapper {
            header {
                .chprLogo img {
                    padding-top: 10em;
                }
            }

            .grid-container {
                grid-template-columns: 0.1fr 0.3fr 0.3fr 0.1fr;
                grid-template-areas:
                    ". title. "
                    " . leftContent rightContent";
            }

            #clients {
                .arrowBox {
                    .clientsArrow {
                        left: -60px;
                    }
                }
            }

            #services {
                .arrowBox {
                    .servicesArrow {
                        left: -20px;
                    }
                }
            }
        }

        section {
            font-size: 0.9em;
            padding: 1em 0;

            .keepCentral {
                width: 1em;
            }
        }
    }
}

// }

//iPad Mini
@media only screen and (min-width: 768px) {
    body {
        font-size: 1.3em !important;

        .wrapper {
            section {
                font-size: 1em;
                padding: 10em 0;

                .keepCentral {
                    width: 33em;
                }
            }

            header {
                .chprLogo img {
                    width: 50%;
                    padding-top: 20em;
                }

                .headerArrow {
                    width: 40px;
                    height: 110px;
                    background-size: 40px 110px;
                }

                .whoarewe {
                    max-width: 18em;
                    padding-bottom: 30%;
                }
            }

            #clients {
                .arrowBox {
                    .clientsArrow {
                        width: 80px;
                        height: 240px;
                        left: 10px;
                        top: -210px;
                    }
                }

                .grid-container {
                    grid-template-columns: repeat(2, minmax(10em, 13em));
                    grid-template-areas:
                        "title title"
                        "leftContent rightContent";

                    .leftSide {
                        padding-left: 2em;
                    }
                }
            }

            #services {
                .arrowBox {
                    .servicesArrow {
                        left: 110px;
                        top: -300px;
                        width: 230px;
                        height: 330px;
                    }
                }

                .grid-container {
                    grid-template-columns: repeat(2, minmax(10em, 13em));
                }
            }

            #contact {
                .arrowBox {
                    .contactArrow {
                        background-image: url(../../../images/contactArrowMed.svg);
                        left: 50px;
                        top: -295px;
                        width: 350px;
                        height: 330px;
                    }
                }

                .grid-container {
                    grid-template-columns: repeat(2, minmax(10em, 13em)) 2em;
                }

                .backtotop {
                    a {
                        font-size: 0.9em;
                        padding-top: 20px;
                    }
                }
            }

            footer {
                padding: 1em 1em;

                ul {
                    width: 100%;

                    li {
                        display: inline-block;

                        a {
                            font-size: 0.82em;
                        }
                    }

                    .left {
                        float: left;
                    }

                    .right {
                        float: right;
                    }
                }
            }

            #privacyPolicy {
                width: 80%;
            }
        }
    }
}

@media only screen and (min-width: 768px) and (orientation: landscape) {
    body {
        font-size: 1.3em !important;

        .wrapper {
            section {
                font-size: 1em;
                padding: 8em 0;

                .keepCentral {
                    width: 33em;
                }
            }

            header {
                .chprLogo img {
                    width: 50%;
                    padding-top: 15em;
                }
            }
        }
    }
}

//Desktop
@media only screen and (min-width: 1300px) {
    body {
        .wrapper {
            section {
                padding: 18em 0;

                .keepCentral {
                    width: 50em;
                }
            }

            header {
                .chprLogo img {
                    padding-top: 17em;
                    max-width: 700px;
                }

                .whoarewe {
                    padding-bottom: 10%;
                }
            }

            #clients {
                .arrowBox {
                    .clientsArrow {
                        top: -290px;
                        width: 80px;
                        height: 330px;
                    }
                }

                .grid-container {
                    grid-template-columns: repeat(3, minmax(10em, 13em));
                    grid-template-areas: "title leftContent rightContent";

                    .leftSide {
                        padding-left: 0em;
                    }
                }
            }

            #services {
                .arrowBox {
                    .servicesArrow {
                        background-image: url(../../../images/servicesArrowLrg.svg);
                        background-repeat: no-repeat;
                        left: 90px;
                        top: -280px;
                        width: 280px;
                        height: 750px;
                    }
                }
            }

            //Contact
            #contact {
                .title {
                    padding-left: 1em;
                }

                .arrowBox {
                    .contactArrow {
                        background-image: url(../../../images/contactArrowLarge.svg);
                        left: -40px;
                        top: -500px;
                        width: 90%;
                        height: 290px;
                        transform: scaleX(1.1);
                    }
                }

                .grid-container {
                    grid-template-columns: 2em repeat(3, minmax(10em, 22em));
                    grid-template-areas: "btt title leftContent rightContent";

                    .rightSide {
                        .divider2 {
                            display: grid;
                            border: 0;
                            height: 1px;
                            background: rgba(247, 215, 208, 0.507);
                        }
                    }

                    .backtotop {
                        a {
                            font-size: 0.9em;
                            left: -70px;
                            width: 300px;
                            top: -90px;
                        }
                    }
                }
            }

            #privacyPolicy {
                width: 45%;
            }
        }
    }
}
